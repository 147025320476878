<template>

<div style="margin-top: -100px"> 
    <div class="navbar navbar-default header-highlight">
        <div class="navbar-header">

            <a class="navbar-brand" href="#" style="height: auto;">
                <img src="@/assets/logo_totem_adm.png" alt="">
            </a>
        </div>
    </div>
    <div class="page-header">
        <div class="page-header-content">
            <div class="page-title">
                <h4>
                    <span class="text-semibold"><strong>DashBoard</strong></span> - Administrativo
                </h4>
            </div>
            <div class="heading-elements">
                <div class="heading-btn-group"></div>
            </div>
        </div>  
    </div>

    <div class="content">
        <div class="tabbable">
            <ul class=" nav-tabs nav-justified bg-slate-700" style="padding: 20px;">
                <li>
                    <a  data-toggle="tab" v-on:click="verificarStatus()" class="legitRipple adm" >STATUS PAYGO </a>                                   
                </li> 
                <li class="active">
                    <a  data-toggle="tab" v-on:click="getTransacoes()" class="legitRipple adm" >TRANSAÇÕES</a>
                </li>
                <li class>
                    <a  data-toggle="tab" v-on:click="menuAdm()" class="legitRipple adm" > MENU ADMINISTRATIVO</a>
                </li>
            </ul>          
        </div>
        
        <div class="container-fluid ajuste-pagina">           
            <div class="row">
                <div class="col-xs-12" >
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h3 class="panel-title"> 
                               <b> Filtros</b>
                            </h3> 
                        </div> 
                        <div class="panel-body">
                            <form class="form">
                                <div class="row">
                                    <div class="form-group col-xs-12 col-sm-4">
                                        <label>Cliente</label> 
                                        <input type="text" class="form-control" v-model="nome">
                                    </div>  
                                    <div class="form-group col-xs-12 col-sm-4" >
                                        <label>Número do cartão</label> 
                                        <input type="text" class="form-control" v-model="cartao">
                                    </div> 
                                    <div class="form-group col-xs-12 col-sm-4">
                                        <label>Status</label> 
                                        <select class="form-control" v-model="status">
                                            <option value="Transacao Autorizada"> Transação Autorizada </option> 
                                            <option value="Transacao Pendente"> Transação Pendente </option>
                                        </select>
                                    </div>   
                                </div> 
                                <div class="row">
                                    <div class="form-group col-xs-12 col-sm-4" >
                                        <label> Data do Pagamento </label> 
                                        <input type="text" class="form-control" v-maska="'##/##/####'" v-model="datapagamento">     
                                    </div>
                                </div>
                            </form> 
                        </div> 
                        <div class="panel-footer">
                            <div class="row">
                                <div class="col-xs-8 col-xs-offset-2 text-center" >
                                    <button type="button" class="btn bg-red" @click="limparDados()">
                                        Limpar  
                                        <font-awesome-icon icon="fa-regular fa-trash-can"/>
                                    </button> 
                                </div>          
                            </div>
                        </div>
                    </div>
                </div>
                          
                <div class="col-xs-12">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h3 class="panel-title">
                                <b>Transações</b> 
                            </h3>    
                        </div> 
    
                        
                        <div class="table-responsive ">
                            <table class="table table-hover text-size-small table-striped ">
                                <thead>
                                    <tr>
                                        <th> Data </th> 
                                        <th> Cliente </th> 
                                        <th> Valor </th>
                                        <th> Número Cartão </th>
                                        <th> Bandeira </th>
                                        <th> Pix </th>
                                        <th> Status </th>
                                        <th> Ações </th>
                                    </tr>
                                </thead>
        
                                <tbody >
                                    <tr v-for="transacao in lista" v-bind:key="transacao.nsu">
                                        <td> {{ transacao.data }} </td> 
                                        <td> {{ transacao.cliente }} </td> 
                                        <td> {{ transacao.valor_cobrado }} </td>
                                        <td>
                                            <b> {{ transacao.numero_cartao }} </b>
                                        </td>
                                        <td> {{ transacao.bandeira }} </td>
                                        <td> {{ transacao.pix ? 'SIM' : '' }} 
                                        </td>                                     
                                        <td>
                                            <b> {{ transacao.msg_retorno}} </b>
                                        </td>  
                                        <td v-if="transacao.pix == null">
                                            <button  type="button" class="btn btn-danger" v-on:click="cancelarOperacao(transacao)">
                                            <font-awesome-icon icon="fa-solid fa-arrow-rotate-left" color="white" data-toggle="tooltip"
                                            data-placement="top" title="Estornar Pagamento"/>
                                            </button>
                                        </td>
                                        <td v-else>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>  
                
                <div class="row">
                    <div class="col-xs-12">
                        <loading v-model:active="isLoading" 
                                    :is-full-page="fullPage">
                        </loading>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import Swal from 'sweetalert2';
    import axios from 'axios'; 
    import Loading from 'vue3-loading-overlay'; 
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'; 
    import {API_URL} from '@/services/constantes'
    
    export default defineComponent({
        name: 'totem-adm',

        components: {
            Loading,
        },

        data: function(){
            return{
                transacoes:[],
                id_totem: localStorage.getItem("id_totem"),
                nome:"",
                status: null,
                cartao:"",
                datapagamento:"",
                isLoading:false,
                fullPage:true,
            }
        },
    
        sockets: {
            connect: function () {
                console.log('socket verificacaostatus')
            },
            
            totemAtivo: function (arquivo_status) {   
                if(arquivo_status == true) {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: 'A Paygo está funcionando normalmente.',
                        icon: 'success',
                    });       
                } else {
                    Swal.fire({
                        title: 'Erro!',
                        text: 'A Paygo está desconectada.',
                        icon: 'error',
                    });
                }
            },

            cancelaTransacao(transacao){
                this.transacoesCanceladas(transacao)
            }
        },

        created(){
            this.getTransacoes()
        },

        computed: {
            lista: function() {
                let lista = this.transacoes;
                if (this.nome) {
                    return lista.filter(res => {
                        res = Object.values(res);
                        for (let k = 0; k < res.length; k++) {
                            if ((res[k] + "").toLowerCase().indexOf(this.nome.toLowerCase()) >= 0) {
                                return true;
                            }
                        }
                        return false;
                    });
                } else if  (this.cartao) {
                    return lista.filter(res => {
                        res = Object.values(res);
                        for (let k = 0; k < res.length; k++) {
                            if ((res[k] + "").toLowerCase().indexOf(this.cartao.toLowerCase()) >= 0) {
                                return true;
                            }
                        }
                        return false;
                    });
                } else if ( this.status){
                    return lista.filter(res => {
                        res = Object.values(res);
                        for (let k = 0; k < res.length; k++) {
                            if ((res[k] + "").toLowerCase().indexOf(this.status.toLowerCase()) >= 0) {
                                return true;
                            }
                        }
                        return false;
                    });
                } else {
                    return lista.filter(res => {
                        res = Object.values(res);
                        for (let k = 0; k < res.length; k++) {
                            if ((res[k] + "").toLowerCase().indexOf(this.datapagamento.toLowerCase()) >= 0) {
                                return true;
                            }         
                        }
                        return false;
                    }); 
                }  
            },   
        },

        methods:{

            getTransacoes(){
                axios({
                method: "get",
                url: API_URL + "getTransacoes", 
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("token") }
            }).then(response => {
                if (response.data.status == "ok") {   
                    this.transacoes = response.data.result     
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.errors,
                    });
                }
            }).catch(error => {
               Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.errors, 
                });
            });
            },          

            transacoesCanceladas(transacao){ 
                axios.put(API_URL  + "cancelaPagamento", 
                {parametros:transacao},
                {headers: { 'Authorization': 'Bearer ' + localStorage.getItem("token") }}
                ).then(response => {
                if(response.data.status == "ok"){
                    if(transacao.status == 0){
                        Swal.fire({
                        title: 'Atenção',
                        text: 'Transação cancelada com sucesso!',
                        icon: 'success', 
                        confirmButtonText: 'Ok'
                        })
                        this.getTransacoes();
                    } else {
                        Swal.fire({
                        title: 'Erro!',
                        text: 'Transação não pode ser cancelada!',
                        icon: 'error', 
                        confirmButtonText: 'OK'
                        })
                    }
                }
            }).catch(error => {
               Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.errors, 
                });
            });  
            },

            verificarStatus(){
                Swal.fire({
                    title: 'Atenção',
                    text: 'Certeza que deseja verificar o status da Paygo?',
                    icon: 'question', 
                    showDenyButton: true,
                    confirmButtonText: 'Sim',
                    denyButtonText: 'Não',
                }).then((result) => {
                    if (result.isConfirmed) {   
                        this.isLoading = true;                   
                        this.$socket.emit('verificarStatus',{id_totem:this.id_totem});
                        this.isLoading = false;
                    } else if (result.isDenied) {
                        return ""
                    }
                });   
            },   

            menuAdm(){
                Swal.fire({
                    title: 'Atenção',
                    text: 'Certeza que deseja abrir o meu Administrativo?',
                    icon: 'question', 
                    showDenyButton: true,
                confirmButtonText: 'Sim',
                denyButtonText: 'Não',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.isLoading = false;
                    this.$socket.emit('abrirMenuAdm',{id_totem:this.id_totem});
                } else if (result.isDenied) {
                    return ""
                }
                });   
            },
            
            cancelarOperacao(transacao){
                Swal.fire({
                    title: 'Atenção',
                    text: 'Certeza que deseja cancelar a operação?',
                    icon: 'question', 
                    showDenyButton: true,
                confirmButtonText: 'Sim',
                denyButtonText: 'Não',
            }).then((result) => {
                if (result.isConfirmed) {
                    let dados = {transacao: transacao, id_totem: this.id_totem}; 
                   this.$socket.emit('apagarTransacao', dados) 
                } else if (result.isDenied) {
                    return ""
                }
                });
            },          

            limparDados() {
                this.nome = "";
                this.status = null;
                this.cartao = "";
                this.datapagamento = "";
            },
        }
    })
</script>