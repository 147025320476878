import { createApp } from 'vue'
import App from './App.vue'
import roteador from './roteador'
import axios from 'axios'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

import SocketIO from 'socket.io-client'
import VueSocketIO from 'vue-3-socket.io'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas, far)
import Maska  from 'maska'


createApp(App)
    .use(Maska)
    .use(roteador)
    .use(new VueSocketIO({
        debug: true,
        connection: SocketIO('https://adm.totalvia.com.br:3000', {
            withCredentials: true,
        }),
    }))
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')

