<template>
    <HeaderPage/>

        <div class="text-pagamento">
            <strong>Pagamento</strong> realizado com <strong>sucesso</strong> :)<br>      
        </div> 
        <div class="text-obg-pgt">
            Obrigado por realizar seu pagamento. 
        </div>
        <!-- 
        <div class="icon-check fa-10x">
            <font-awesome-icon icon="far fa-check-circle" color="purple" />
        </div>
        -->
         <div class="row">
            <div class="col-8 offset-2">
                <div class="icon-pagamentorealizado">
                    <Vue3Lottie :animation-data="pagamentorealizadoJson" />
                </div>
            </div>
        </div>
        <div class="imprimir-comprovante">
            Deseja imprimir seu comprovante?
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-6 text-center">
                    <div class="row">
                        <div class="offset-3 col-6">
                            <button class="btn btn-lg  btn-block bg-red" @click="finalizarProcesso()">  
                                Finalizar 
                                  <font-awesome-icon icon="far fa-check-circle" color="white"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-6 text-center">
                    <div class="row">
                        <div class="offset-3 col-6">
                             <button class="btn btn-lg btn-block bg-imprimir" @click="imprimirComprovante()">   
                                Imprimir 
                                  <font-awesome-icon icon="fas fa-receipt" color="white"/>  
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <img  class="quick-inferior-esquerdo" src="../assets/quick_mascote_totalvia.png" alt="">  
    <FooterPage></FooterPage>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { Vue3Lottie } from 'vue3-lottie'
import pagamentorealizadoJson from '@/assets/animacoes/pagamentorealizado.json'
import HeaderPage from './fixos/HeaderPage.vue';
import FooterPage from './fixos/FooterPage.vue';

export default defineComponent({
    name: 'pagamento-realizado',

    components: {
        Vue3Lottie,
        HeaderPage,
        FooterPage
    },

    data() {
        return {
            pagamentorealizadoJson,
            id_totem: localStorage.getItem("id_totem"),
        }
    },

    sockets: {
        connect: function () {   
            this.imprimirComprovante();
            this.finalizarProcesso();
        },
    },

    methods: {
        finalizarProcesso() {
            this.$socket.emit('apagarResposta', {id_totem: this.id_totem})
            this.$router.push({ path: '/primeira-tela'})
        },

        imprimirComprovante() {
            console.log({id_totem: this.id_totem});
            this.$socket.emit('imprimirComprovanteTotem', {id_totem: this.id_totem})
            this.$router.push({ path: '/primeira-tela'})
        }
    }
})

</script>
