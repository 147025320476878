import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import PrimeiraTela from '@/components/PrimeiraTela.vue';
import PrimeiroPasso from '@/components/PrimeiroPasso.vue';
import BuscaCadastro from '@/components/BuscaCadastro.vue';
import voltarPrimeiroPasso from '@/components/PrimeiroPasso.vue';
import BoletosAbertos from '@/components/BoletosAbertos.vue';
import PagamentoRealizado from '@/components/PagamentoRealizado.vue';
import ErroPagamento from '@/components/ErroPagamento.vue';
import LoginInicial from '@/components/LoginInicial.vue';
import EscolhaPagamento from '@/components/EscolhaPagamento.vue';
import TotemAdm from '@/components/TotemAdm.vue';


const rotas: RouteRecordRaw[] = [{
    path: '/',
    name: 'LoginInicial',
    component: LoginInicial
},
{
    path: '/primeiro-passo',
    name: 'PrimeiroPasso',
    component: PrimeiroPasso
},
{
    path: '/busca-cadastro/:tipo_pesquisa',
    name: 'BuscaCadastro',
    component: BuscaCadastro
},
{
    path: '/primeiro-passo',
    name: 'voltarPrimeiroPasso',
    component: voltarPrimeiroPasso
},
{
    path: '/boletos-abertos/:codigo_cliente/',
    name: 'boletosAbertos',
    component: BoletosAbertos,
    props: true

},
{
    path: '/pagamento-realizado',
    name: 'pagamentoRealizado',
    component: PagamentoRealizado
},
{
    path: '/erro-pagamento',
    name: 'erropagamento',
    component: ErroPagamento
},
{
    path: '/primeira-tela',
    name: 'primeiraTela',
    component: PrimeiraTela
},
{
    path: '/escolha-pagamento',
    name: 'escolha-pagamento',
    component: EscolhaPagamento,
    props: route => ({
        itensPagamento: route.query.itensPagamento,
        permiteParcelamento: route.query.permiteParcelamento
    })
},
{
    path: '/totem-adm',
    name: 'totemAdm',
    component: TotemAdm,
}
]

const roteador = createRouter({
    history: createWebHashHistory(),
    routes: rotas,
})

roteador.beforeEach(async to => {
    if (!localStorage.getItem('token') && to.name !== 'LoginInicial') {
        return { name: 'LoginInicial' };
    }
})

export default roteador; 
