<template>
    <p class="copyright"> totalvia todos os direitos reservados - {{this.ano}}&copy;</p> 
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({

    name: 'FooterPage',

    data:function(){
        return{
            ano: new Date().getFullYear()
        }
    }
})
</script>
