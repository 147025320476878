<template>
    <HeaderPage/>
    
        <div class="text-erro-pagamento">
            Algo deu <strong>errado</strong> com seu <strong>pagamento. :(</strong>
        </div>
       
        <div class="row">
            <div class="col-6 offset-4">
                <div class="icon-erro">
                    <Vue3Lottie :animation-data="erroJson" />
                </div>
            </div>
        </div>
        <div class="text-alerta-erro-pagamento">
            Infelizmente não foi possível receber seu pagamento, faça uma nova tentativa.
        </div>
        <div class="row">
            <div class="col-4 offset-4 text-center">
                <button class="bbtn btn-lg  btn-block bg-red" @click="voltarPrimeiraTela()">  
                    Finalizar 
                    <font-awesome-icon icon="far fa-check-circle" color="white"/>  
                </button>     
            </div>
        </div>
    <img  class="quick-inferior-esquerdo" src="../assets/quick_mascote_totalvia.png" alt=""> 
    <FooterPage></FooterPage>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { Vue3Lottie } from 'vue3-lottie'
import erroJson from '@/assets/animacoes/erro.json'
import HeaderPage from './fixos/HeaderPage.vue';
import FooterPage from './fixos/FooterPage.vue';

export default defineComponent({
    name: 'erro-pagamento',

    components: {
        Vue3Lottie,
        HeaderPage, 
        FooterPage
    },

     data() {
        return {
            erroJson
        }
    },

    sockets: {
        connect: function () {   
            this.voltarPrimeiraTela();
        },
    },

    methods: {
        voltarPrimeiraTela() {
            this.$socket.emit('apagarResposta');
            this.$router.push({path:'/primeira-tela'}); 
        }
    }
})

</script>