<template>
    <HeaderPage/>
    <p class="titulo-primeiratela"> Como você <strong>prefere buscar</strong> pelo seu cadastro?</p>
        
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        <p class="text-tipo-busca">Nome <strong>completo</strong></p> 
                    </div>
                    <div class="col-4">
                        <p class="text-tipo-busca"><strong>C.P.F</strong></p>
                    </div>
                    <div class="col-4">
                        <p class="text-tipo-busca"><strong>LOGIN</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4 text-center">
                        <div class="row">
                            <div class="offset-3 col-6">
                                <button class="btn btn-lg  btn-block bg-green btn-rounded" @click="buscacadastro('nome')" >  
                                    <font-awesome-icon icon="fa-solid fa-magnifying-glass" color="white"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center">
                        <div class="row">
                            <div class="offset-3 col-6">
                                <button class="btn btn-lg btn-block btn-rounded" @click="buscacadastro('cpf')" style="background:linear-gradient( 130deg, #B92184, #656ADF, #2CA0C9); border:0px">  
                                    <font-awesome-icon icon="fa-solid fa-magnifying-glass" color="white"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center">
                        <div class="row">
                            <div class="offset-3 col-6">
                                <button class="btn btn-lg btn-block bg-blue btn-rounded" @click="buscacadastro('login')">  
                                    <font-awesome-icon icon="fa-solid fa-magnifying-glass" color="white"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
                
            <div class="col-12 text-center space">
                <div class="row">
                    <div class="offset-5 col-2 ">
                        <button class="btn btn-lg btn-block bg-red btn-rounded" @click="voltar()">  
                            Voltar
                            <font-awesome-icon icon="fas fa-reply" color="white"/>
                        </button>
                    </div>
                </div>
            </div>
          
        </div>   
        <img  class="quick-inferior-direito" src="../assets/quick_mascote_totalvia.png" alt="">
     
    <FooterPage></FooterPage>
      
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import HeaderPage from './fixos/HeaderPage.vue';
import FooterPage from './fixos/FooterPage.vue';

export default defineComponent({

    name: 'primeiro-passo',

    components: {
        HeaderPage,
        FooterPage
    },

    methods: {
        buscacadastro (tipo: string) {
            this.$router.push({path:'/busca-cadastro/'+tipo}); 
        },
        voltar(){
            this.$router.push({path:'/primeira-tela'});
        }
    }
})
</script>

<style>

</style>