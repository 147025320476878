<template>
    <HeaderPage/>

        <p>
            Clique em <strong>iniciar</strong> para pagar sua mensalidade.
        </p>
        <img  class="quick" src="../assets/quick_mascote_totalvia.png" alt="">
        
        <div class="row">
            <div class="col-6 offset-4">
                <div class="icon-iniciar" @click="alterarPagina" >
                    <Vue3Lottie :animation-data="iniciarJson" />
                </div>
            </div>
        </div>
        <FooterPage></FooterPage>  
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { Vue3Lottie } from 'vue3-lottie'
import iniciarJson from '@/assets/animacoes/iniciar.json'
import HeaderPage from './fixos/HeaderPage.vue';
import FooterPage from './fixos/FooterPage.vue';

export default defineComponent({
    name: 'PrimeiraTela',

    components: {
        Vue3Lottie,
        HeaderPage,
        FooterPage
    },

    data() {
        return {
            iniciarJson
        }
    },
    
    methods: {
        alterarPagina () {
            this.$router.push({path:'/primeiro-passo'}); 
        }
    }
})
</script>



