<template>
    <HeaderPage/>

    <div>
        <div v-if="!mostrar">
            <div class="row" >
                <div class="col-xs-10 col-xs-offset-1 borda-escolha-pagamento" @click="mostrar=!mostrar;exibecartao=false;getPix()" >
                    <div class="" >
                        <div class="row" >
                            <div class="col-xs-8 text-escolha-pagamento">
                                -Pagar com Pix
                            </div>
                        </div>   
                    </div>
                </div>

                <div class="col-xs-10 col-xs-offset-1 borda-escolha-pagamento" @click="mostrar=!mostrar;exibePix=true; pagarCartao({itensPagamento},permiteParcelamento)">
                    <div class="" >
                        <div class="row">
                            <div class="col-xs-10 text-escolha-pagamento" >
                                -Pagar com cartão de crédito/débito  
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div >
                <div v-if="!exibePix">
                    <span class="text-QR-Code">
                        Leia o QR Code <strong>dentro do app </strong>do seu banco.
                    </span>
                    <div class="row">
                        <div class="col-xs-10 col-xs-offset-1">
                            <div class="alert alert-danger no-border">
                                <span class="text-semibold">Atenção!</span> O Pagamento pode levar até <b>1h e 30m</b> para ser
                                processado.
                            </div>
                            <div class="row">
                                <div class="col-6 offset-3 text-center">
                                    <div  v-if="!this.pix" class="qrcode-animacao">
                                        <Vue3Lottie :animation-data="qrcodeJson" />
                                    </div>
                                    <div v-else class="qr-code">
                                        <QRCodeVue3 :value="this.pix"  
                                                    :height="300" :width="300" :backgroundOptions="{ color: '#ffffff' }" 
                                                    v-bind:image="iconUrl" :imageOptions="{  imageSize: 0.5, }"
                                                    backgroundColor="white" :dotsOptions="{ color: 'black',}" />                                                                                        
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                       
                    <div class="row">
                        <div class="col-3 offset-2 text-center">
                            <button class="btn btn-lg btn-block bg-green" @click="voltar()">  
                                Novo pagamento 
                                <font-awesome-icon icon="fa fa-reply" color="white"/>  
                            </button>     
                        </div>
                        <div class="col-3 offset-2 text-center">
                            <button class="btn btn-lg btn-block bg-red" @click="finalizarProcesso()">  
                                Finalizar 
                                <font-awesome-icon icon="far fa-check-circle" color="white"/>  
                            </button>     
                        </div>
                    </div>
                </div>
                
                <div v-else>
                    <span class="text-cartao">
                        Insira ou aproxime seu <strong>cartão </strong> da maquininha.
                    </span>
                    <div class="row">
                        <div class="col-xs-12 col-xs-offset-1">
                            <div class="row">
                                <div class="col-6 offset-3">
                                    <div class="cartao-credito">
                                        <Vue3Lottie :animation-data="cartaocreditoJson"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-center">
                            <div class="row">
                                <div class="offset-3 col-6">
                                    <button class="btn btn-lg btn-block bg-green" @click="voltar()">
                                        Novo Pagamento 
                                        <font-awesome-icon icon="fa fa-reply" color="white"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 text-center">
                            <div class="row">
                                <div class="offset-3 col-6">
                                    <button class="btn btn-lg btn-block bg-red" @click="finalizarProcesso()">
                                        Finalizar 
                                        <font-awesome-icon icon="fa-solid fa-xmark" color="white"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <loading :active="isLoading" 
                                                :is-full-page="fullPage">
                                    </loading>
                                </div>
                            </div>
                        
                    </div> 
                </div>  
            </div>
        </div>
    </div>
    <FooterPage></FooterPage>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Vue3Lottie } from 'vue3-lottie';
import qrcodeJson from '../assets/animacoes/qrcode.json';
import cartaocreditoJson from '../assets/animacoes/cartaocredito.json';
import Swal from 'sweetalert2';
import axios from 'axios';
import Loading from 'vue3-loading-overlay'; 
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import QRCodeVue3 from 'qrcode-vue3';

import HeaderPage from './fixos/HeaderPage.vue';
import FooterPage from './fixos/FooterPage.vue';
import {API_URL, BASE_API_URL} from '@/services/constantes'

export default defineComponent({
    name: 'escolha-pagamento',

    components: {
        Vue3Lottie,
        Loading,
        QRCodeVue3,
        HeaderPage,
        FooterPage
    },

    props: {
        itensPagamento: String,
        permiteParcelamento: String
    },

    data:function() {
        return{
            isLoading:true,
            fullPage:true,
            mostrar:false,
            exibePix:false,
            exibecartao:false,
            qrcodeJson,
            cartaocreditoJson,
            exibeAnimacao: true,
            pixPagamento:'',
            iconUrl: require("@/assets/logo_black.png"),
            pix: '',
            pix_txid: '',
            consultaPagamento: false,
            intervalo: "",
        }
    },  
    watch: {
		consultaPagamento() {
			if (this.consultaPagamento) {
				this.intervalo = setInterval(() => {
					axios.get(BASE_API_URL+ "consultaPagamentoPixTotem/" + this.pix_txid).then((response) => {
						if (response.status == 200) {
							clearInterval(this.intervalo);
							Swal.fire({
                                icon: "success",
                                title: "Recebemos sua solicitação!", 
                                text: "Seu pagamento agora está em estado de processamento, não se preocupe, seus acessos caso advertidos já estão sendo liberados.", 
                            });
						}
					});
				}, 5000);
			} else {
				clearInterval(this.intervalo);
			}
		},
	},

    sockets: {
        connect: function () {
            console.log('socket connected')
        },
        
        pagamentoConcluido: function (parametros:any) {
            this.baixaCadastro(parametros);
            this.isLoading = false;
            this.pagamentoRealizado(parametros);
        }
    },

    mounted() {
        this.escondeAnimacao();
    },

    methods:{
        getPix(){
            let dadosPagamento = JSON.parse(atob(atob(atob(this.itensPagamento))));
            if(dadosPagamento.mensalidades.length > 1 || dadosPagamento.boletos.length > 1 || (dadosPagamento.mensalidades.length > 0 && dadosPagamento.boletos.length > 0)){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Não é possível pagar mais de uma mensalidade ou boleto com Pix!',
                });
                this.voltar();
            } else {
                this.pix = dadosPagamento.pix;
                this.pix_txid = dadosPagamento.pix_txid;
                this.consultaPagamento = true;
                this.baixaPagamento(dadosPagamento, this.pix_txid);
                if(!this.pix){
                    if (dadosPagamento.mensalidades.length > 0){
                        axios.post(API_URL + "pix/",{itensPagamento: dadosPagamento}, 
                        {headers: { 'Authorization': 'Bearer ' + localStorage.getItem("token") }}
                    ).then((response) => {
                            if(response.data.status == "ok"){
                                this.pix = response.data.result.pix.textoImagemQRcode;
                                this.pix_txid = response.data.result.pix.txid;
                                this.consultaPagamento = true;
                                this.baixaPagamento(dadosPagamento, this.pix_txid);
                            }
                        }).catch(() => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Algo deu errado ao registrar a mensalidade!',
                            });
                        });
                    } else {
                        axios.post(API_URL + "pix/",{itensPagamento: dadosPagamento}, 
                        {headers: { 'Authorization': 'Bearer ' + localStorage.getItem("token") }})
                            .then((response) => {
                                if (response.data.status == "ok") {
                                    this.pix = response.data.result.pix.textoImagemQRcode;
                                    this.pix_txid = response.data.result.pix.txid;
                                    this.consultaPagamento = true;
                                    this.baixaPagamento(dadosPagamento, this.pix_txid);
                                }
                            }).catch(() => {
                                Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Algo deu errado ao registrar o boleto!',
                            });
                        });
                    }
                }
            }
        },

        baixaPagamento(dadosPagamento, pix_txid){
            axios.put(API_URL + "pixBaixaPagamento", 
                {dadosPagamento: dadosPagamento, pix_txid: pix_txid},
                {headers: { 'Authorization': 'Bearer ' + localStorage.getItem("token") }}
            ).then((response) => {
                if(response.data.status == "ok"){
                    Swal.fire({
                        icon: 'success',
                        title: response.data.result,
                        
                    });
                }
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.errors, 
                });
            });
        },

        voltar(){           
            this.$router.push({path: '/boletos-abertos/' + btoa(btoa(btoa(JSON.parse(this.itensPagamento).cliente)))});
        },
       
        finalizarProcesso() {
            this.$socket.emit('apagarResposta')
            this.$router.push({ path: '/primeira-tela'}) 
        },

        voltarInicio() { 
            Swal.fire({
                icon:'question',
                title: 'Atenção!',
                text: 'Tem certeza que deseja finalizar a operação?',
                showDenyButton: true,
                confirmButtonText: 'Sim',
                denyButtonText: 'Não',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.mostrar=false
                } else if (result.isDenied) {
                    return ""
                }
            }) 
        },

        escondeAnimacao() {
            setTimeout(() => {
                this.exibeAnimacao = false;
            }, 7000);
        },     
        pagarCartao: function async (data,permiteParcelamento) {
            console.log(permiteParcelamento);
            data.id_totem = localStorage.getItem("id_totem");
            data.itensPagamento = atob(atob(atob(data.itensPagamento)));
            Swal.fire({  
                title: 'Escolha a opção do cartão',
                icon: 'question',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Cartão de crédito',
                denyButtonText: 'Cartão de débito',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false, 
                }).then((result) => {
                if (result.isConfirmed) { 
                    data.cartao = 1;   
                    if(permiteParcelamento != "false"){
                        Swal.fire({  
                            title: 'Escolha o tipo do pagamento',
                            icon: 'question',
                            showDenyButton: true,
                            showCancelButton: true,
                            confirmButtonText: 'À vista',
                            denyButtonText: 'Parcelado',
                            cancelButtonText: 'Cancelar',
                            allowOutsideClick: false,
                        }).then(async (result) =>  {
                            if (result.isConfirmed) {  
                                data.financiamento = 1; //confirmar se é parcelado pelo emissor ou pelo estabelecimento 
                                data.pagamento = 1;
                                this.$socket.emit('iniciarPagamento', data);
                            } else if (result.isDenied){
                                data.financiamento = 2; //confirmar se é parcelado pelo emissor ou pelo estabelecimento 
                                data.pagamento = 1;
                                const { value: parcelas } = await Swal.fire({
                                                                title: 'Quantidade de parcelas',
                                                                icon: 'question',
                                                                text: 'Escolha quantas vezes deseja parcelar',
                                                                input: 'select',
                                                                inputOptions: {
                                                                    2: '2x',
                                                                    3: '3x',
                                                                    4: '4x',
                                                                    5: '5x',
                                                                    6: '6x',
                                                                },
                                                                inputPlaceholder: 'Quantidade de parcelas',
                                                                showCancelButton: true,
                                                                inputValidator: (nparcelas) => {
                                                                    return new Promise((resolve) => {
                                                                        if (Number(nparcelas) >= 2 && Number(nparcelas) <= 6) {
                                                                            resolve('')
                                                                        } else {
                                                                            resolve('Escolha uma opção válida.')
                                                                        }
                                                                    });
                                                                }
                                                            });
                                if (parcelas) {
                                    data.parcelas = parcelas;
                                    this.$socket.emit('iniciarPagamento', data);
                                }else{
                                    this.finalizarProcesso();
                                }
                            }
                            else {  
                                this.finalizarProcesso();
                            } 
                        });
                    } else {  
                        if (result.isConfirmed) {  
                            data.financiamento = 1; 
                            data.pagamento = 1;
                            this.$socket.emit('iniciarPagamento', data);

                        } else{
                            this.voltarInicio();
                        }
                    }    
                } else if (result.isDenied) {
                    data.cartao = 2;
                    data.financiamento = 1;
                    data.pagamento = 1;
                    this.$socket.emit('iniciarPagamento', data)
                } else {
                    this.finalizarProcesso();
                } 
            })  
            
        },

        baixaCadastro(parametros) {
            axios.put( API_URL + "baixaPagamento", 
                {parametros:parametros, itensPagamento: JSON.parse(atob(atob(atob(this.itensPagamento))))},
                {headers: { 'Authorization': 'Bearer ' + localStorage.getItem("token") }}
                ).then(response => {
                if(response.data.status=="ok"){
                    if(response.data.requerConfirmacao && response.data.requerConfirmacao == 'sim'){
                        //this.$socket.emit('confirmaPagamento', parametros);
                    }           
                }
            }).catch(error => {
               Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.errors, 
                });
            });
        },

        pagamentoRealizado(parametros) {
            if(parametros.status == 0){
                this.$router.push({path: '/pagamento-realizado'})
            }else{
                this.$socket.emit('apagarResposta')
                this.$router.push({path: '/erro-pagamento'});
            } 
        }       
    } 
})

</script>