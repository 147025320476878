<template>
    <HeaderPage></HeaderPage>

    <div class="container">
        <div class="row">
            <div class="col-xs-6 col-xs-offset-3">
                <div class="panel panel-login">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-xs-12 text-center">
                                <a href="#" class="active" id="login-form-link">Login</a>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-xs-12">
                                <form id="login-form" method="post"
                                    role="form" style="display: block;">
                                    <div class="form-group">
                                        <input type="text" name="username" id="username" class="form-control" autocomplete="off"
                                            v-model="login" placeholder="Login">
                                    </div>
                                    <div class="form-group">
                                        <input type="password" name="password" id="password" class="form-control"
                                            v-model="senha" placeholder="Senha">
                                    </div>
                                    
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-sm-12 col-sm-offset-5">
                                                <button v-on:click="logar()" type="button" class="btn btn-primary">
                                                    Entrar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterPage></FooterPage>
    </div>
</template>

<script lang="ts">

import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL } from '@/services/constantes';
import FooterPage from './fixos/FooterPage.vue';
import HeaderPage from './fixos/HeaderPage.vue';

export default ({

    components: {
        FooterPage,
        HeaderPage
    },
    data: function () {
        return {
            login: "",
            senha: ""
        }
    },
    methods: {
        logar() {
            if (this.login == "" || this.senha == "") {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Preencha todos os dados',
                })
            } else {
                axios.get(API_URL + "auth?login=" + this.login + "&passwd=" + this.senha)
                .then(response => {
                    if (response.data.status=="ok") {
                        localStorage.setItem("token", response.data.result);
                        localStorage.setItem("id_totem", this.login.toUpperCase());
                        this.$router.push({ path: '/primeira-tela' });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Login ou senha incorretos',
                        });
                    }
                }).catch(()=>{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Algo deu errado ao efetuar o login',
                    });
                });
            }
        }
    }

})
</script>