<template>
	<HeaderPage />

	<div>
		<div v-if="!buscar">
			<div class="backg-boletos">
				<p class="text-boletos-abertos">Além da <strong>mensalidade,</strong> você tem <strong>outros boletos</strong> em aberto.</p>
				<p class="text-escolha-pagar">Escolha <strong>qual deseja</strong> pagar :)</p>

				<div class="row">
					<div class="col-xs-10 col-xs-offset-1 borda-opcoes">
						<div
							class=""
							@click="
								buscar = !buscar;
								exibeTodos = true;
							"
						>
							<div class="borda-colorida"></div>
							<div class="row">
								<div class="col-xs-7 text-lista-opcoes">-Mostrar todos os débitos em aberto</div>
								<div class="col-xs-4 text-center text-lista-opcoes">
									<span> Valor: R$ {{ valorTotal }} </span>
								</div>
								<div class="col-xs-1">
									<font-awesome-icon icon="fas fa-check" color="purple" class="icon-lista-opcoes" />
								</div>
							</div>
						</div>
					</div>

					<div class="col-xs-10 col-xs-offset-1 borda-opcoes">
						<div
							@click="
								buscar = !buscar;
								exibeMensalidade = true;
							"
						>
							<div class="borda-colorida"></div>
							<div class="row">
								<div class="col-xs-7 text-lista-opcoes">-Mensalidade internet</div>
								<div class="col-xs-4 text-center text-lista-opcoes">
									<span> Valor: R$ {{ valorMensalidade }} </span>
								</div>
								<div class="col-xs-1">
									<font-awesome-icon icon="far fa-list-alt" color="purple" class="icon-lista-opcoes" />
								</div>
							</div>
						</div>
					</div>

					<div class="col-xs-10 col-xs-offset-1 borda-opcoes">
						<div
							@click="
								buscar = !buscar;
								exibeBoletoAvulso = true;
							"
						>
							<div class="borda-colorida"></div>
							<div class="row">
								<div class="col-xs-7 text-lista-opcoes">-Boletos Avulso</div>
								<div class="col-xs-4 text-center text-lista-opcoes">
									<span> Valor: R$ {{ valorBoleto }} </span>
								</div>
								<div class="col-xs-1">
									<font-awesome-icon icon="fas fa-home-lg" color="purple" class="icon-lista-opcoes" />
								</div>
							</div>
						</div>
					</div>

					<div class="col-4 offset-4 text-center btn-finalizar-busca">
						<button class="bbtn btn-lg btn-block bg-red" @click="sair()">
							Finalizar
							<font-awesome-icon icon="fa-solid fa-xmark" color="white" />
						</button>
					</div>
				</div>
			</div>
		</div>

		<div v-else>
			<div>
				<div class="ajuste-pagina">
					<div v-if="exibeTodos || exibeBoletoAvulso">
						<div v-for="boleto in boletos_abertos" v-bind:key="boleto.cod" type="text" @click="selecionarBoleto(boleto)">
							<ul :class="' escolha-debitos ' + bgBoletoSelecionado(boleto)">
								<li class="sem-ponto">Referente à: {{ boleto.mensagens }}</li>
								<li class="sem-ponto">Valor: {{ boleto.valor }}</li>
								<li class="sem-ponto">Vencimento: {{ boleto.vencimento }}</li>
							</ul>
						</div>
					</div>

					<div v-if="exibeTodos || exibeMensalidade">
						<div v-for="mensalidade in mensalidades_abertas" v-bind:key="mensalidade.cod" type="text" @click="selecionarMensalidade(mensalidade)">
							<ul :class="' escolha-debitos ' + bgMensalidadeSelecionada(mensalidade)" v-if="mensalidade.status_processamento == 0">
								<li v-if="mensalidade && mensalidade.vencimento" class="sem-ponto">Referente à: {{ referencia(mensalidade.vencimento) }}</li>
								<li class="sem-ponto">Valor: {{ mensalidade.valor }}</li>
								<li class="sem-ponto">
									<b v-if="mensalidade.parcela == 'vencido'" class="text-danger"> Vencimento: {{ mensalidade.vencimento }} </b>
									<span v-else> Vencimento: {{ mensalidade.vencimento }} </span>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div>
					<div class="row">
						<div class="col-10 offset-2 text-center">
							<div class="row">
								<div class="col-3">
									<a
										class="btn btn-lg btn-block"
										@click="
											buscar = !buscar;
											(exibeMensalidade = false), (exibeTodos = false), (exibeBoletoAvulso = false);
										"
										style="background: linear-gradient(130deg, #b92184, #656adf, #2ca0c9); color: white"
									>
										Voltar
									</a>
								</div>
								<div class="col-3">
									<a class="btn btn-lg bg-green btn-block" @click="enviandoCodigos()"> Pagamento </a>
								</div>
								<div class="col-3">
									<a class="btn btn-lg bg-red btn-block" @click="sair()"> Finalizar </a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import HeaderPage from "@/components/fixos/HeaderPage.vue";
import { API_URL } from "@/services/constantes";

export default defineComponent({
	name: "boletos-abertos",

	components: {
		HeaderPage,
	},

    props:{
        codigo_cliente: String
    },

	data: function () {
		return {
			pesquisa: "",
			boletos_abertos: [],
			mensalidades_abertas: [],
			itensPagamento: {
				cliente: 0,
				nome: "",
				cpf: "",
				mensalidades: [],
				boletos: [],
				pix: "",
				pix_txid: "",
				vencimento: "",
			},
			buscar: false,
			exibeTodos: false,
			exibeMensalidade: false,
			exibeBoletoAvulso: false,
			valorTotal: 0,
			valorMensalidade: 0,
			valorBoleto: 0,
			cliente: 0,
			boleto_pagar: [],
			mensalidade_pagar: [],
		};
	},

	mounted() {
		this.getDebitos();
	},

	methods: {
		referencia(value: string) {
			if (value) {
				let dados = value.split("/");

				let mes = dados[1];

				if (mes == "01") {
					return "01/12/" + (parseInt(dados[2]) - 1) + " - 31/12/" + (parseInt(dados[2]) - 1);
				}

				if (mes == "12") {
					return "01/11/" + dados[2] + " - 30/11/" + dados[2];
				}
				if (mes == "11") {
					return "01/10/" + dados[2] + " - 31/10/" + dados[2];
				}
				if (mes == "10") {
					return "01/09/" + dados[2] + " - 30/09/" + dados[2];
				}
				if (mes == "09") {
					return "01/08/" + dados[2] + " - 31/08/" + dados[2];
				}
				if (mes == "08") {
					return "01/07/" + dados[2] + " - 31/07/" + dados[2];
				}
				if (mes == "07") {
					return "01/06/" + dados[2] + " - 30/06/" + dados[2];
				}
				if (mes == "06") {
					return "01/05/" + dados[2] + " - 31/05/" + dados[2];
				}
				if (mes == "05") {
					return "01/04/" + dados[2] + " - 30/04/" + dados[2];
				}
				if (mes == "04") {
					return "01/03/" + dados[2] + " - 31/03/" + dados[2];
				}
				if (mes == "03") {
					return "01/02/" + dados[2] + " - 28/02/" + dados[2];
				}
				if (mes == "02") {
					return "01/01/" + dados[2] + " - 31/01/" + dados[2];
				}
			}
		},
		getDebitos() {
			this.cliente = atob(atob(atob(this.$route.params.codigo_cliente)));
			axios({
				method: "get",
				url: API_URL + "buscaDebitosCliente?codigo_cliente=" + this.$route.params.codigo_cliente,
				headers: { Authorization: "Bearer " + localStorage.getItem("token") },
			})
				.then((response) => {
					if (response.data.status == "ok") {
						this.boletos_abertos = response.data.result.boletos_abertos;
						this.mensalidades_abertas = response.data.result.mensalidades_abertas;
						this.mostrarValores();
					} else {
						if (response.status == 204) {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: "Sua pesquisa não encontrou nenhum resultado.",
							});
						} else {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: response.data.errors,
							});
						}
					}
				})
				.catch((error) => {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: error.response.data.errors,
					});
				});
		},

		mostrarValores() {
			this.boletos_abertos.forEach((element) => {
				let valor = element["valor"].replace(".", "");
				valor = valor.replace(",", ".");
				valor = valor.replace("R$", "");
				this.valorBoleto = Number(this.valorBoleto) + Number(valor);
			});

			this.mensalidades_abertas.forEach((element) => {
				let valor = element["valor"].replace(".", "");
				valor = valor.replace(",", ".");
				valor = valor.replace("R$", "");
				this.valorMensalidade = Number(this.valorMensalidade) + Number(valor);
			});
			this.valorTotal = this.valorBoleto + this.valorMensalidade;

			this.valorBoleto = parseFloat(this.valorBoleto).toFixed(2).replace(".", ",");
			this.valorMensalidade = parseFloat(this.valorMensalidade).toFixed(2).replace(".", ",");
			this.valorTotal = parseFloat(this.valorTotal).toFixed(2).replace(".", ",");
		},

		enviandoCodigos() {        
			let valorTotal = 0;
			let permiteParcelamento = true;
			let totalMensalidade = 0;
            
			if (this.exibeTodos || this.exibeMensalidade) {
				this.mensalidade_pagar.forEach((element) => {
					let valor = element["valor"].replace("R$", "");
					valor = valor.replace(".", "");
					valor = valor.replace(",", ".");
					this.itensPagamento.mensalidades.push(element.cod);
					this.itensPagamento.pix = element.pix;
					this.itensPagamento.pix_txid = element.pix_txid;
					this.itensPagamento.vencimento = element.vencimento;
					this.itensPagamento.nome = element.nome;
					this.itensPagamento.cpf = element.cpf;
					valorTotal = valorTotal + parseFloat(valor);
				});

				totalMensalidade = valorTotal;
				if (this.mensalidade_pagar.length > 0) {
					permiteParcelamento = false;
				}
			}

			if (this.exibeTodos || this.exibeBoletoAvulso) {
				this.boleto_pagar.forEach((element) => {
					let valor = element["valor"].replace("R$", "");
					valor = valor.replace(".", "");
					valor = valor.replace(",", ".");
					this.itensPagamento.boletos.push(element.cod);
					this.itensPagamento.pix = element.pix;
					this.itensPagamento.pix_txid = element.pix_txid;
					this.itensPagamento.vencimento = element.vencimento;
					this.itensPagamento.nome = element.nome;
					this.itensPagamento.cpf = element.cpf;
					valorTotal = valorTotal + parseFloat(valor);
				});
			}

			if (valorTotal > 0) {
				let data = new Date();
				var dados = {
					codigoCliente: this.cliente + data.getSeconds(),
					cliente: this.cliente,
					nome: this.itensPagamento.nome,
					cpf: this.itensPagamento.cpf,
					valorPagamento: valorTotal,
					mensalidades: this.itensPagamento.mensalidades,
					boletos: this.itensPagamento.boletos,
					pix: this.itensPagamento.pix,
					pix_txid: this.itensPagamento.pix_txid,
					vencimento: this.itensPagamento.vencimento,
				};
               
				if (permiteParcelamento) {
					if (valorTotal < 10) {
						permiteParcelamento = false;
					}
					this.$router.push({
						name: "escolha-pagamento",
						query: {
							itensPagamento: btoa(btoa(btoa(JSON.stringify(dados)))),
							permiteParcelamento: permiteParcelamento,
						},
					});
				} else if (this.itensPagamento.mensalidades.length > 0 && valorTotal - totalMensalidade >= 10) {
					Swal.fire({
						title: "Atenção!",
						text: "Não é permitido o parcelamento de mensalidades, caso deseje realizar o PARCELAMENTO remova a(s) mensalidade(s) da seleção.",
						icon: "warning",
						showDenyButton: true,
						confirmButtonText: "Prosseguir sem o parcelamento",
						denyButtonText: "Desejo Parcelar",
					}).then((result) => {
						if (result.isConfirmed) {
							this.$router.push({
								name: "escolha-pagamento",
								query: {
									itensPagamento: btoa(btoa(btoa(JSON.stringify(dados)))),
									permiteParcelamento: false,
								},
							});
						} else if (result.isDenied) {
							return "";
						}
					});
				} else {
					this.$router.push({
						name: "escolha-pagamento",
						query: {
							itensPagamento: btoa(btoa(btoa(JSON.stringify(dados)))),
							permiteParcelamento: permiteParcelamento,
						},
					});
				}
			} else {
				Swal.fire({
					title: "Atenção!",
					text: "Você deve selecionar ao menos uma mensalidade!",
					icon: "error",
					showDenyButton: false,
					confirmButtonText: "OK",
				});
			}
		},

		sair() {
			Swal.fire({
				icon: "question",
				title: "Atenção!",
				text: "Tem certeza que deseja finalizar a operação?",
				showDenyButton: true,
				confirmButtonText: "Sim",
				denyButtonText: "Não",
			}).then((result) => {
				if (result.isConfirmed) {
					this.$router.push({ path: "/primeira-tela" });
				} else if (result.isDenied) {
					return "";
				}
			});
		},

		bgBoletoSelecionado(boleto) {
			let encontrou = false;
			this.boleto_pagar.forEach((element) => {
				if (boleto == element) {
					encontrou = true;
				}
			});
			if (encontrou) {
				return "bg-success";
			} else {
				return "";
			}
		},

		bgMensalidadeSelecionada(mensalidade) {
			let encontrou = false;
			this.mensalidade_pagar.forEach((element) => {
				if (mensalidade == element) {
					encontrou = true;
				}
			});
			if (encontrou) {
				return "bg-success";
			} else {
				return "";
			}
		},

		selecionarBoleto(boleto) {
			let achou = false;
			this.boleto_pagar.forEach((element) => {
				if (boleto == element) {
					achou = true;
				}
			});
			if (achou) {
				this.boleto_pagar.splice(this.boleto_pagar.indexOf(boleto), 1);
			} else {
				this.checkMultiplasEscolhas();
				this.boleto_pagar.push(boleto);
			}
		},

		checkMultiplasEscolhas() {
			if (this.mensalidade_pagar.length > 0 || this.boleto_pagar > 0) {
				Swal.fire({
					title: "Atenção!",
					text: "CASO A FORMA DE PAGAMENTO SEJA PIX ESCOLHA APENAS UMA MENSALIDADE POR VEZ.",
					icon: "warning",
					showDenyButton: false,
					confirmButtonText: "OK",
				});
			}
		},
		selecionarMensalidade(mensalidade) {
			let achou = false;
			this.mensalidade_pagar.forEach((element) => {
				if (mensalidade == element) {
					achou = true;
				}
			});
			if (achou) {
				this.mensalidade_pagar.splice(this.mensalidade_pagar.indexOf(mensalidade), 1);
			} else {
				this.checkMultiplasEscolhas();
				this.mensalidade_pagar.push(mensalidade);
			}
		},
	},
});
</script>
