<template>
    <div>
        <HeaderPage/>
        <div>
            <div v-if="!buscar" id="busca-cadastro">
                <p class="titulo-confirmando-dados"><strong>Encontrando</strong> seu cadastro.</p>

                <form class="form">
                    <input value="" id="pesquisa" class="barra-pesquisar use-keyboard-input" type="text" autocomplete="off" onfocus="this.value=''"     
                        :placeholder="'Digite o ' + $route.params.tipo_pesquisa + ' do cadastro.'" style="color: white"/>
                </form>

                <div class="row space">
                    <div class="col-6 text-center">
                        <div class="row">
                            <div class="offset-3 col-6">
                                <button class="btn btn-lg btn-block bg-red btn-rounded" @click="voltar()">  
                                    Voltar
                                    <font-awesome-icon icon="fas fa-reply" color="white"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 text-center">
                        <div class="row">
                            <div class="offset-3 col-6">
                                <button class="btn btn-lg btn-block bg-green btn-rounded " @click="pesquisaCadastro()">  
                                    Pesquisar 
                                    <font-awesome-icon icon="fa-solid fa-magnifying-glass" color="white"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>              
            </div>
            
            <div v-else>
                <p class="text-encontrar-cadastro">
                    Confirmando seus <strong>dados.</strong>
                </p> 

                <p class="text-subtitulo-cadastro">
                    Encontramos <strong>este cadastro </strong> com as informações preenchidas.
                </p>


                <div class="dados-encontrados">
                    <ul type="text">

                        <li>
                            Nome: {{ cliente.nome }}
                        </li>
                        <li>
                            CPF: {{ cliente.cpf }}
                        </li>
                        <li>
                            Planos: <div v-for="plano in cliente.planos" :key="plano.codigo_acesso">
                                <strong>{{ plano.plano }}</strong><br>
                            </div>
                        </li>
                        <li>
                            Endereço: {{ cliente.endereco }}
                        </li>
                        <li>
                            Valor: {{ cliente.valor }}
                        </li>
                    </ul>
                </div>
                <input value="" id="nascimento" class="data-nascimento use-keyboard-input" v-maska="'##/##/####'" type="text" placeholder="dd/mm/aaaa" required onfocus="this.value=''" autocomplete="off" style="color: white">
                <p class="text-nascimento">
                    Digite a <strong>data</strong> de nascimento <strong>do assinante</strong> no
                    espaço acima.
                </p>

                <div class="row">
                    <div class="col-6 text-center">
                        <div class="row">
                            <div class="offset-3 col-6">
                                <a class="btn btn-lg btn-block bg-red" @click="finalizar()"
                                    style="border:0px;border-radius: 30px">
                                    Finalizar
                                    <font-awesome-icon icon="fa-solid fa-xmark" color="white" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 text-center">
                        <div class="row">
                            <div class="offset-3 col-6">
                                <button class="btn btn-lg btn-block btn-rounded" type="button" @click="validarNascimento()"
                                    style="background: linear-gradient( 130deg, #B92184, #656ADF, #2CA0C9);border:0px;border-radius: 30px; color: white;">
                                    Confirmar
                                    <font-awesome-icon icon="far fa-check-circle" color="white" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterPage></FooterPage>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios';
import Swal from 'sweetalert2';
import Keyboard from '@/assets/tecladoJS/tecladoTotem.js'
import HeaderPage from './fixos/HeaderPage.vue';
import FooterPage from './fixos/FooterPage.vue';
import {API_URL} from '@/services/constantes'

export default defineComponent({
    name: 'BuscaCadastro',

    components: {
        HeaderPage,
        FooterPage,
    },
    data: function () {
        return {
            pesquisa: "",
            cliente: {
                nome: "",
                cpf: "",
                codigo_cliente: "",
                valor: "",
                endereco: "",
                planos: {
                    plano: "",
                    codigo_acesso: 0,
                },
            },
            buscar: false,
            dataNascimento:"",
        }
    },

    mounted() {
        Keyboard.init()
    },

    watch: {
        buscar(valorNovo){
            if (valorNovo == true){
                setTimeout(() => {
                    Keyboard.clear();  
                }, 3000);
            }
        }
    },

    methods: {

        pesquisaCadastro() {
            const input = document.getElementById('pesquisa') as HTMLInputElement | null;
            // 👉️ input has type HTMLInputElement or null here
            if (input != null) {
                this.pesquisa= input.value
                 // 👉️ input has type HTMLInputElement here
            }   
            axios({
                method: "get",
                url: API_URL + "buscaCliente?" + this.$route.params.tipo_pesquisa + "=" + this.pesquisa,
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("token") }
            }).then(response => {
                if (response.data.status == "ok") {
                    this.cliente = response.data.result;
                    this.buscar = true;
                    Keyboard.close();
                } else {
                    if (response.status == 204){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Sua pesquisa não encontrou nenhum resultado.',
                        });
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.errors,
                        });
                    }
                }
            }).catch(error => {
               Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.errors,  
                });
            });
        },

        validarNascimento(){
            const input = document.getElementById('nascimento') as HTMLInputElement | null;
            // 👉️ input has type HTMLInputElement or null here
            if (input != null) {
                this.dataNascimento = input.value
                 // 👉️ input has type HTMLInputElement here
            } 
            if(this.cliente.data_nascimento == this.dataNascimento){
                Keyboard.close();
                if(this.cliente.possui_debitos == false){
                    Swal.fire({
                        icon:'warning',
                        title: 'Atenção!',
                        text: 'Este cadastro não possui débitos!',
                        confirmButtonText: 'OK',
                    })
                } else {         
                    this.$router.push({ path: '/boletos-abertos/'+this.cliente.codigo_cliente});
                }    
            } else {    
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Dados incorretos',
                })
            }  
        },

        tratarData: function(dataNascimento) {
            if(dataNascimento){
                let data = dataNascimento.split("-");

                return data[2]+"/"+data[1]+"/"+data[0];
            }
            else{
                return '';
            }
        },

        finalizar() {           
            this.$router.push({ path: '/primeira-tela' });
        },
        voltar(){
            this.$router.push({ path: '/primeiro-passo' });
        },
        limparData() {
            Keyboard.limpar()
        }
    }
})
</script>

